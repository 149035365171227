@import '../../../../../../scss/_variables.scss';

.groupHeader {
  padding-bottom: 5px;
  padding-top: 10px;
  border-bottom: 1px solid $brand-default;
}

.queueList {
  list-style: none;
  padding-left: 0;
}
