// FNI Color Palette
//Blue Primary
$fni-primary-50: #dbefff;
$fni-primary-100: #8fccff;
$fni-primary-200: #57b3ff;
$fni-primary-300: #0f93ff;
$fni-primary-400: #0084f0;
$fni-primary-500: #0073d1;
$fni-primary-600: #0062b2;
$fni-primary-700: #005194;
$fni-primary-800: #004075;
$fni-primary-900: #003057;
$fni-primary-A100: #dbefff;
$fni-primary-A200: #8fccff;
$fni-primary-A400: #0084f0;
$fni-primary-A700: #005194;
$fni-primary: $fni-primary-500;

//Success
$fni-success-50: #bbffc2;
$fni-success-100: #6fff7e;
$fni-success-200: #37ff4c;
$fni-success-300: #00ee1a;
$fni-success-400: #00d016;
$fni-success-500: #00b113;
$fni-success-600: #009210;
$fni-success-700: #00740c;
$fni-success-800: #005509;
$fni-success-900: #003706;
$fni-success-A100: #bbffc2;
$fni-success-A200: #6fff7e;
$fni-success-A400: #00d016;
$fni-success-A700: #00740c;
$fni-success: $fni-success-500;

//Info
$fni-info-50: #fff;
$fni-info-100: #c0e7f7;
$fni-info-200: #8ed4f1;
$fni-info-300: #4ebbe9;
$fni-info-400: #33b1e5;
$fni-info-500: #1ca5de;
$fni-info-600: #1991c3;
$fni-info-700: #157da8;
$fni-info-800: #12688c;
$fni-info-900: #0e5471;
$fni-info-A100: #fff;
$fni-info-A200: #c0e7f7;
$fni-info-A400: #33b1e5;
$fni-info-A700: #157da8;
$fni-info: $fni-info-500;

//Warning
$fni-warning-50: #fff;
$fni-warning-100: #fce9d8;
$fni-warning-200: #f9cca4;
$fni-warning-300: #f4a761;
$fni-warning-400: #f29745;
$fni-warning-500: #f08728;
$fni-warning-600: #e97710;
$fni-warning-700: #cd690e;
$fni-warning-800: #b05a0c;
$fni-warning-900: #934b0a;
$fni-warning-A100: #fff;
$fni-warning-A200: #fce9d8;
$fni-warning-A400: #f29745;
$fni-warning-A700: #cd690e;
$fni-warning: $fni-warning-500;

//Danger
$fni-danger-50: #fff;
$fni-danger-100: #f5c8c4;
$fni-danger-200: #ed9c94;
$fni-danger-300: #e36357;
$fni-danger-400: #df4a3d;
$fni-danger-500: #d93324;
$fni-danger-600: #bf2d20;
$fni-danger-700: #a4271b;
$fni-danger-800: #8a2017;
$fni-danger-900: #701a13;
$fni-danger-A100: #fff;
$fni-danger-A200: #f5c8c4;
$fni-danger-A400: #df4a3d;
$fni-danger-A700: #a4271b;
$fni-danger: $fni-danger-500;

//Default
$fni-default-50: #fff;
$fni-default-100: #fff;
$fni-default-200: #f9f9f9;
$fni-default-300: #d6d6d6;
$fni-default-400: #c6c6c6;
$fni-default-500: #b7b7b7;
$fni-default-600: #a8a8a8;
$fni-default-700: #989898;
$fni-default-800: #898989;
$fni-default-900: #7a7a7a;
$fni-default-A100: #fff;
$fni-default-A200: #fff;
$fni-default-A400: #c6c6c6;
$fni-default-A700: #989898;
$fni-default: $fni-default-500;

$black: #000;
$rgb-black: '0,0,0' !default;
$white: #fff;
$rgb-white: '255,255,255' !default;
