@import '../../../../scss/_variables.scss';

.filterList {
  list-style: none;
  padding-left: 0;
}

.listHeader {
  margin-top: 0;
  border-bottom: 1px solid $brand-default;
  padding-bottom: 10px;
}
