@import '../../../../../../scss/_variables.scss';

.queueListItem {
  padding: 5px 0;
}

.queueName {
  line-height: 30px;

  &.selectable {
    cursor: pointer;
    color: $brand-primary;

    &:hover {
      color: darken($brand-primary, 30%);
    }
  }
}

.selected {
  font-weight: bold;
  font-size: 14px;
}

.nameCol {
  padding-right: 10px;
}
