@import '../../../../../scss/_variables.scss';

.container {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 5px 5px 0;
}

.deletableContainer {
  //background-color: darken(#fff, 10%);
  font-weight: bold;
  font-size: 14px;
}

.item {
  cursor: pointer;
  color: $brand-primary;

  &:hover {
    color: darken($brand-primary, 30%);
  }
}

.itemName {
  width: 90%;
}

.iconItem {
  height: 20px;

  svg {
    width: 20px;
    cursor: pointer;
  }
}

.selected {
  font-weight: bold;
  font-size: 14px;
}
